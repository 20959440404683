.App {
  font-family: Roboto, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.TopBar {
  min-height: 15vmin;
  background-color: black;
  font-weight: 900;
  font-size: 80%;
  white-space: nowrap;
  color: white;
  padding-left: 10%;

  transition: background 0.5s ease-in-out;

  display: flex;
}

.BottomBar {
  min-height: 10vmin;
  background-color: rgb(40, 40, 40);
  font-weight: 900;
  font-size: 80%;
  white-space: nowrap;
  color: white;
  padding-top: 50vmin;
  padding-left: 10%;
}

.BottomBar:hover {
  background-color: rgb(0, 0, 0);
  transition: background 0.5s ease-in-out;
}

.Name {
  display: flex;
  flex-grow: 2;
  justify-self: flex-start;
  align-self: center;

  font-size: 2em;
}

.top_links > a:link,
.top_links > a:visited {
  text-decoration: none;

  color: white;
  border: 2px solid rgb(255, 255, 255);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.top_links > a:hover,
.top_links > a:active {
  background-color: white;
  color: black;
}

.icon {
  display: none;
}

.Intro_quip > a:link,
.Intro_quip > a:visited {
  text-decoration: none;
  color: rgb(0, 0, 0);
}
.Intro_quip > a:hover,
.Intro_quip > a:active {
  color: rgb(0, 129, 17);
}

.Links {
  display: flex;

  justify-content: space-evenly;
  align-items: center;

  font-size: 1.5em;
}

.Intro_name {
  font-size: 40px;
  text-align: left;
  padding: 10% 10% 10% 10%;
  max-width: 50%;
}
.Intro_quip {
  display: block;
  font-size: 18px;
  line-height: 1.5em;
}

@media only screen and (max-width: 800px) {
  .TopBar {
    padding-right: 5%;
  }
  .Links {
    height: 10vmin;
  }
  .top_links {
    display: none;
  }

  .responsive {
    display: flex;
    align-items: center;
  }

  .icon {
    display: block;
    align-items: center;
    padding-right: 5vmin;
  }

  .icon > img {
    width: 30px;
  }

  .Intro_name {
    max-width: 100%;
  }
}

.Card {
  padding: 10%;
  width: 80%;
  display: inline-block;
  position: relative;
}
.Card > img {
  display: block;
  width: 100%;
  border-radius: 5px;
}

@media only screen and (min-width: 800px) {
  .responsive {
    display: none;
  }

  .top_links {
    padding-right: 5vmin;
  }

  .Container {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
    padding-left: 10%;
    row-gap: 10%;
    column-gap: 10%;
  }
  .Card {
    background-color: rgba(236, 236, 236, 1);
    height: 30vw;
    width: 30vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.979),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);

    position: relative;
    display: inline-block;
  }
  .Card > img {
    display: block;
    margin: auto;
    margin-top: 25%;
    width: 90%;
    border-radius: 5px;
  }
}

.Overlay {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;

  opacity: 0;
  transition: opacity 0.5s ease;
}

.Overlay h2,
p {
  padding-left: 10%;
  padding-right: 10%;
}
.Overlay h2 {
  padding-top: 5%;
}

.Card:hover .Overlay {
  color: white;
  display: block;
  background-color: black;
  opacity: 0.7;
}
